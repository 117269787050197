export const CHANGE_STATE = 'CHANGE_STATE'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

export function changeState(settings) {
	return {
		payload: settings,
		type: CHANGE_STATE
	}
}

export function deleteCategory(id) {
	return {
		payload: id,
		type: DELETE_CATEGORY
	}
}