import { post, json, dadata, findByDadataId, formData } from './base'

export const APP_ROUTE = '/application'

const LOGOUT = 'login/out'
export const REGISTER = 'registration/add'
export const LOGIN = 'login/auth'
export const LOG_OUT = 'login/out'

export const QUESTIONS = 'books/secretquestion'
export const LANGUAGES = 'books/lang'
export const CATEGORIES = 'books/category'
export const REGIONS = 'region/list'
export const REGION_CITIES = 'region/city'
export const UNITS = 'unit'
export const CURRENCY = 'currency'
export const TIMEZONES = 'books/timezone'

export const RESTORE_CODE_TO_EMAIL = 'restoreb/email'
export const RESTORE_CODE_TO_PHONE = 'restoreb/phone'
export const RESTORE_BY_PHONE = 'restoreb/passphone'
export const RESTORE_BY_EMAIL = 'restoreb/passmail'

export const CONFIRM_EMAIL = 'confirmb/email'
export const CONFIRM_PHONE = 'confirmb/phone'
export const PROFILE_VIEW_NAME = 'profile/viewfio'
export const PROFILE_EDIT_NAME = 'profile/editfio'
export const PROFILE_VIEW = 'profile/view'
export const PROFILE_SAVE = 'profile/edit'
export const PROFILE_UPLOAD_PHOTO = 'profile/upload'
export const PROFILE_ACCESSES = 'profile/permission'
export const DADATA_SEARCH = 'data/party'

export const MARKET_LIST = 'emavatar/marketplace/list'
export const MARKET_VIEW = 'emavatar/marketplace/view'
export const ADD_TO_CART = 'emavatar/marketplace/cart'

export const CART = 'emavatar/cart/list'
export const CART_EDIT_COUNT = 'emavatar/cart/edit'
export const CART_DELETE = 'emavatar/cart/delete'
export const CART_CREATE_ORDER = 'emavatar/cart/payment'

export const CHANGE_PASSWORD = 'security/password'
export const PROFILE_CONFIRM_EMAIL = 'security/confemail'
export const PROFILE_CONFIRM_PHONE = 'security/confphone'
export const PROFILE_CHANGE_EMAIL = 'security/email'
export const PROFILE_CHANGE_PHONE = 'security/phone'
export const PROFILE_CONTACTS = 'security/view'

export const USER_AVATAR_LIST = 'worktables/list'
export const USER_AVATAR_VIEW = 'worktables/view'
export const USER_AVATAR_IDENTIFY = 'worktables/id'

export const I_AM_EMPLOYEE_LIST = 'employee/list'
export const I_AM_EMPLOYEE_QUIT = 'employee/quit'
export const I_AM_EMPLOYEE_VIEW = 'employee/view'

export const MY_ORG_LIST = 'myorg/list'
export const MY_ORG_VIEW = 'myorg/view'
export const MY_ORG_DADATA_TOKEN = 'myorg/dadata'
export const MY_ORG_ADD = 'myorg/add'
export const MY_ORG_HEADS = 'myorg/selection'
export const MY_ORG_EDIT = 'myorg/edit'
export const MY_ORG_DELETE = 'myorg/delete'
export const MY_ORG_MODERATION = 'myorg/moderat'
export const MY_INACTIVE_ORG_LIST = 'myorg/mlist'
export const MY_INACTIVE_ORG_EDIT = 'myorg/medit'

export const COMPANY_ADD = 'admin/organizations/add'
export const COMPANY_EDIT = 'admin/organizations/edit'
export const COMPANY_LIST = 'admin/organizations/list'
export const COMPANY_VIEW = 'admin/organizations/view'
export const COMPANY_CONFIRM = 'admin/organizations/confirm'
export const COMPANY_DELETE = 'admin/organizations/delete'
export const COMPANY_HEAD_ORG = 'admin/organizations/selection'

export const POSITION_LIST = 'admin/officer/list'
export const POSITION_VIEW = 'admin/officer/view'
export const POSITION_ADD = 'admin/officer/add'
export const POSITION_EDIT = 'admin/officer/edit'
export const POSITION_DELETE = 'admin/officer/delete'

export const EMPLOYEE_LIST = 'admin/employee/list'
export const EMPLOYEE_VIEW = 'admin/employee/view'
export const EMPLOYEE_EDIT = 'admin/employee/edit'
export const EMPLOYEE_DELETE = 'admin/employee/delete'
export const EMPLOYEE_ADDITIONAL_DATA = 'admin/employee/selection'

export const ACCESS_GROUP_LIST = 'admin/permission/list'
export const ACCESS_GROUP_ACCESSES = 'admin/permission/selection'
export const ACCESS_GROUP_ADD = 'admin/permission/add'
export const ACCESS_GROUP_EDIT = 'admin/permission/edit'
export const ACCESS_GROUP_VIEW = 'admin/permission/view'
export const ACCESS_GROUP_DELETE = 'admin/permission/delete'

export const INVITATION_ADDITIONAL_DATA = 'admin/staffinvite/selection'
export const INVITATION_LIST = 'admin/staffinvite/list'
export const INVITATION_ADD = 'admin/staffinvite/add'
export const INVITATION_EDIT = 'admin/staffinvite/edit'
export const INVITATION_VIEW = 'admin/staffinvite/view'
export const INVITATION_RESPOND = 'admin/staffinvite/action'

export const INVITATIONS = 'invitebes/list'
export const INVITATIONS_RESPOND = 'invitebes/action'

export const AVATAR_LIST = 'admin/worktables/list'
export const AVATAR_ADD = 'admin/worktables/add'
export const AVATAR_VIEW = 'admin/worktables/view'
export const AVATAR_DELETE = 'admin/worktables/delete'
export const AVATAR_EDIT = 'admin/worktables/edit'
export const AVATAR_ADDITIONAL_DATA = 'admin/worktables/selection'
export const AVATAR_PAY = 'admin/worktables/payment'
export const AVATAR_CONTACTS = 'admin/worktables/contacts'
export const AVATAR_CONTACTS_ALPHABET = 'admin/worktables/abc'
export const AVATAR_EXCLUSION = 'admin/worktables/exclusion'

export const CONTACT_LIST = 'contacts/list'
export const CONTACT_ORG = 'contacts/selection'
export const CONTACT_VIEW = 'contacts/view'
export const CONTACT_EDIT = 'contacts/edit'

export const CONTRACTOR_LIST = 'admin/contractors/list'
export const CONTRACTOR_ORG = 'admin/contractors/selection'
export const CONTRACTOR_VIEW = 'admin/contractors/view'
export const CONTRACTOR_EDIT = 'admin/contractors/edit'

export const MY_CONTACT_LIST = 'admin/mycontact/list'

export const CONTACT_REQUEST_LIST = 'admin/rcontact/list'
export const CONTACT_REQUEST_SEARCH = 'admin/rcontact/search'
export const CONTACT_REQUEST_DATA = 'admin/rcontact/selection'
export const CR_PROVIDER_CREATE = 'admin/rcontact/addprovider'
export const CR_CUSTOMER_CREATE = 'admin/rcontact/addcustomer'
export const CR_CATEGORIES = 'admin/rcontact/listcat'
export const CONTACT_REQUEST_VIEW = 'admin/rcontact/view'
export const CR_COMMENTS = 'admin/rcontact/listcomment'
export const CR_COMMENT_ADD = 'admin/rcontact/comment'
export const CR_ACTION = 'admin/rcontact/action'
export const CR_DELETE = 'admin/rcontact/delete'
export const CR_AVATARS = 'admin/rcontact/avatars'

export const REQUEST_LIST = 'emavatar/requests/list'
export const REQUEST_CREATE = 'emavatar/requests/add'
export const REQUEST_CONTACTS = 'emavatar/requests/contacts'
export const REQUEST_SHOW_CONTACT = 'emavatar/requests/contact'
export const REQUEST_OPEN_CONTACT = 'emavatar/requests/opencontact'
export const REQUEST_PRODUCT_LIMIT = 'emavatar/requests/addproduct'
export const REQUEST_PUBLISH = 'emavatar/requests/notpublics'
export const REQUEST_VIEW = 'emavatar/requests/view'
export const REQUEST_DELETE_DRAFT = 'emavatar/requests/delete'
export const REQUEST_OFFERS = 'emavatar/requests/bidoffer'
export const REQUEST_CANCEL = 'emavatar/requests/cancel'
export const REQUEST_TO_ARCHIVE = 'emavatar/requests/archive'
export const REQUEST_TO_WORK = 'emavatar/requests/work'
export const REQUEST_CONFIRM_OFFERS = 'emavatar/requests/done'
export const REQUEST_CONTRACTORS = 'emavatar/requests/suppliers'
export const CONTRACTOR_OFFERS = 'emavatar/requests/oneoffer'
export const REQUEST_CHANGE_AMOUNT = 'emavatar/requests/quantity'
export const REQUEST_CONTRACTORS_BY_CATEGORY = 'emavatar/requests/categories'
export const REQUEST_PUBLISH_PUBLIC = 'emavatar/requests/publics'

export const QUOTE_LIST = 'emavatar/quote/list'
export const QUOTE_CREATE = 'emavatar/quote/add'
export const QUOTE_CONTACTS = 'emavatar/quote/contacts'
export const QUOTE_PUBLISH = 'emavatar/quote/notpublics'
export const QUOTE_VIEW = 'emavatar/quote/view'
export const QUOTE_DELETE_DRAFT = 'emavatar/quote/delete'
export const QUOTE_OFFERS = 'emavatar/quote/bidoffer'
export const QUOTE_CANCEL = 'emavatar/quote/cancel'
export const QUOTE_TO_ARCHIVE = 'emavatar/quote/archive'
export const QUOTE_TO_WORK = 'emavatar/quote/work'
export const QUOTE_CONFIRM_OFFERS = 'emavatar/quote/done'
export const QUOTE_CONTRACTORS = 'emavatar/quote/contractors'
export const QUOTE_CONTRACTOR_OFFERS = 'emavatar/quote/oneoffer'
export const QUOTE_CHANGE_AMOUNT = 'emavatar/quote/quantity'

export const MY_AVATAR_CONTACTS = 'emavatar/contacts/list'
export const MY_AVATAR_CONTACTS_ALPHABET = 'emavatar/contacts/abc'

export const ORDER_SALE_LIST = 'emavatar/sales/list'
export const ORDER_SALE_TO_WORK = 'emavatar/sales/work'
export const ORDER_SALE_TO_ARCHIVE = 'emavatar/sales/archive'
export const ORDER_SALE_VIEW = 'emavatar/sales/view'
export const ORDER_SALE_OFFER = 'emavatar/sales/add'

export const MY_AVATAR_VIEW = 'emavatar/avatar/view'

export const ADMIN_ORDER_LIST = 'admin/order/list'
export const ADMIN_ORDER_FILTERS = 'admin/order/selection'
export const ADMIN_ORDER_VIEW = 'admin/order/view'
export const ADMIN_ORDER_CHANGE_STATUS = 'admin/order/action'
export const ADMIN_ORDER_TO_ARCHIVE = 'admin/order/archive'
export const ADMIN_ORDER_FROM_ARCHIVE = 'admin/order/work'
export const ADMIN_ORDER_CHANGE_COMMENT = 'admin/order/comment'

export const ADMIN_SUPPLY_LIST = 'admin/supply/list'
export const ADMIN_SUPPLY_FILTERS = 'admin/supply/selection'
export const ADMIN_SUPPLY_VIEW = 'admin/supply/view'
export const ADMIN_SUPPLY_CHANGE_STATUS = 'admin/supply/action'
export const ADMIN_SUPPLY_TO_ARCHIVE = 'admin/supply/archive'
export const ADMIN_SUPPLY_FROM_ARCHIVE = 'admin/supply/work'
export const ADMIN_SUPPLY_CHANGE_COMMENT = 'admin/supply/comment'

export const AVATAR_SUPPLY_LIST = 'emavatar/supply/list'
export const AVATAR_SUPPLY_VIEW = 'emavatar/supply/view'
export const AVATAR_SUPPLY_CHANGE_STATUS = 'emavatar/supply/action'
export const AVATAR_SUPPLY_TO_ARCHIVE = 'emavatar/supply/archive'
export const AVATAR_SUPPLY_FROM_ARCHIVE = 'emavatar/supply/work'
export const AVATAR_SUPPLY_CHANGE_COMMENT = 'emavatar/supply/comment'

export const AVATAR_ORDER_LIST = 'emavatar/order/list'
export const AVATAR_ORDER_VIEW = 'emavatar/order/view'
export const AVATAR_ORDER_CHANGE_STATUS = 'emavatar/order/action'
export const AVATAR_ORDER_TO_ARCHIVE = 'emavatar/order/archive'
export const AVATAR_ORDER_FROM_ARCHIVE = 'emavatar/order/work'
export const AVATAR_ORDER_CHANGE_COMMENT = 'emavatar/order/comment'

export const IND_AV_REQUEST_LIMIT = 'emavatar/requests/goods'
export const IND_AV_REQUEST_LIST = 'emavatar/requests/list'
export const IND_AV_REQUEST_ADD = 'emavatar/requests/add'
export const IND_AV_REQUEST_VIEW = 'emavatar/requests/view'
export const IND_AV_REQUEST_PUBLISH = 'emavatar/requests/save'

export const MODERATION_LIST = 'moderats/requests/list'
export const MODERATION_VIEW = 'moderats/requests/view'
export const MODERATION_ACTION = 'moderats/requests/action'
export const MODERATION_CONTACTS = 'moderats/requests/contacts'
export const MODERATION_PROD_OFFERS = 'moderats/requests/bidoffer'
export const MODERATION_CONTACT_OFFERS = 'moderats/requests/oneoffer'
export const MODERATION_CHANGE_DATE = 'moderats/requests/completion'
export const MODERATION_CHAT = 'moderats/requests/chat'
export const MODERATION_DIALOGS = 'moderats/requests/dialogs'
export const MODERATION_REQUEST_BLOCK = 'moderats/requests/block'
export const MODERATION_REQUEST_UNBLOCK = 'moderats/requests/unblock'
export const MODERATION_REQUEST_COMMENT = 'moderats/requests/comment'
export const MODERATION_EDIT_CATEGORY = 'moderats/requests/editcat'
export const MODERATION_CONTACT_VIEW = 'moderats/requests/contact'
export const MODERATION_CHAT_MARK = 'moderats/requests/mark'

export const PUBLIC_REQUEST_LIST = 'emavatar/topublic/list'
export const PUBLIC_REQUEST_VIEW = 'emavatar/topublic/view'
export const PUBLIC_REQUEST_BY_PURCHASER = 'emavatar/topublic/uclist'
export const PUBLIC_REQUEST_OFFER = 'emavatar/topublic/add'
export const PUBLIC_REQUEST_OFFER_VIEW = 'emavatar/topublic/bet'

export const MODERATION_ORG_LIST = 'moderats/organizations/list'
export const MODERATION_ORG_VIEW = 'moderats/organizations/view'
export const MODERATION_ORG_ACTION = 'moderats/organizations/action'
export const MODERATION_ORG_MIME = 'moderats/organizations/mimes'
export const MODERATION_ORG_SEND = 'moderats/organizations/send'
export const MODERATION_ORG_CONVERSATION = 'moderats/organizations/chat'
export const MODERATION_ORG_DIALOGS = 'moderats/organizations/dialogs'
export const MODERATION_ORG_CHAT_STATUS = 'moderats/organizations/editchat'

export const MODERATION_PRODUCT_LIST = 'moderats/price/list'
export const MODERATION_PRODUCT_ADD = 'moderats/price/add'
export const MODERATION_PRODUCT_VIEW = 'moderats/price/view'
export const MODERATION_PRODUCT_EDIT = 'moderats/price/edit'
export const MODERATION_PRODUCT_DELETE = 'moderats/price/delete'
export const MODERATION_PRODUCT_DEACTIVATE = 'moderats/price/action'

export const MARKET_ORDERS = 'emavatar/services/list'
export const MARKET_ORDER_COUNT = 'emavatar/services/statistics'
export const MARKET_ORDER_VIEW = 'emavatar/services/view'
export const MARKET_ACTIVATION_LIST = 'emavatar/services/activats'
export const MARKET_ACTIVATE = 'emavatar/services/activate'

export const PAYMENT_METHODS = 'moderats/paymethod/list'
export const PAYMENT_METHOD_ADD = 'moderats/paymethod/add'
export const PAYMENT_METHOD_EDIT = 'moderats/paymethod/edit'
export const PAYMENT_METHOD_VIEW = 'moderats/paymethod/view'
export const PAYMENT_METHOD_DELETE = 'moderats/paymethod/delete'

export const ORDER_STATUSES = 'moderats/statusorder/list'
export const ORDER_STATUS_ADD = 'moderats/statusorder/add'
export const ORDER_STATUS_EDIT = 'moderats/statusorder/edit'
export const ORDER_STATUS_VIEW = 'moderats/statusorder/view'
export const ORDER_STATUS_DELETE = 'moderats/statusorder/delete'

export const MODERATION_PAYMENT_VIEW = 'moderats/setorder/view'
export const MODERATION_PAYMENT_EDIT = 'moderats/setorder/edit'
export const MODERATION_PAYMENT_DATA = 'moderats/setorder/selection'

export const PURCHASE_MODERATION_LIST = 'moderats/userorder/list'
export const PURCHASE_MODERATION_VIEW = 'moderats/userorder/view'
export const PURCHASE_MODERATION_STATUSES = 'moderats/userorder/selection'
export const PURCHASE_MODERATION_STATUS_LIST = 'moderats/userorder/setstatus'
export const PURCHASE_MODERATION_ADD_HISTORY = 'moderats/userorderhistory/add'
export const PURCHASE_MODERATION_DIALOGS = 'moderats/userorder/dialogs'
export const PURCHASE_MODERATION_CONVERSATION = 'moderats/userorderchat/list'
export const PURCHASE_MODERATION_MIME_TYPES = 'moderats/userorderchat/mimes'
export const PURCHASE_MODERATION_CHAT_STATUS = 'moderats/userorderchat/editchat'

export const CHAT_SEND_MESSAGE = 'moderats/userorderchat/send'

export const WEB_PAGE_LIST = 'moderats/page/list'
export const WEB_PAGE_ADD = 'moderats/page/add'
export const WEB_PAGE_EDIT = 'moderats/page/edit'
export const WEB_PAGE_VIEW = 'moderats/page/view'
export const WEB_PAGE_DELETE = 'moderats/page/delete'

export const ADMIN_MENU_LIST = 'moderats/adminmenu/list'
export const ADMIN_MENU_EDIT = 'moderats/adminmenu/edit'
export const ADMIN_MENU_VIEW = 'moderats/adminmenu/view'

export const ERROR_CODE_LIST = 'moderats/errormessage/list'
export const ERROR_CODE_ADD = 'moderats/errormessage/add'
export const ERROR_CODE_EDIT = 'moderats/errormessage/edit'
export const ERROR_CODE_VIEW = 'moderats/errormessage/view'
export const ERROR_CODE_DELETE = 'moderats/errormessage/delete'
export const ERROR_CODE_SETTINGS = 'moderats/errormessage/viewset'
export const ERROR_CODE_OLD = 'moderats/errormessage/outmode'
export const ERROR_CODE_EXAMPLE = 'moderats/errormessage/example'
export const ERROR_CODE_PREVIEW = 'moderats/errormessage/linefile'
export const ERROR_CODE_SCAN = 'moderats/errormessage/scanfile'

export const MODERATION_USER_LIST = 'moderats/users/list'
export const MODERATION_USER_VIEW = 'moderats/users/view'
export const U_AVATAR = 'moderats/users/avatars'
export const U_ORG = 'moderats/users/counterparty'
export const MODERATION_USER_BLOCK = 'moderats/users/blockuser'
export const MODERATION_USER_UNBLOCK = 'moderats/users/unlockuser'
export const U_BLOCK_AVATAR = 'moderats/users/blockavatars'
export const U_UNBLOCK_AVATAR = 'moderats/users/unlockavatars'
export const U_BLOCK_ORG = 'moderats/users/blockorg'
export const U_UNBLOCK_ORG = 'moderats/users/unlockorg'
export const U_BLOCK_COMMENT = 'moderats/users/uscomment'
export const U_BLOCK_ORG_COMMENT = 'moderats/users/orgcomment'
export const U_BLOCK_AVATAR_COMMENT = 'moderats/users/avcomment'
export const U_BLOCK_AVATAR_PUBLIC = 'moderats/users/blockpubavatars'
export const U_UNBLOCK_AVATAR_PUBLIC = 'moderats/users/unlockpubavatars'
export const U_BLOCK_AVATAR_PUBLIC_COMMENT = 'moderats/users/avpubcomment'

export const EMAIL_LIST = 'moderats/unsubscribe/list'
export const EMAIL_ACTION = 'moderats/unsubscribe/unlocking'

export const FEEDBACK_SAVE_PUBLIC = 'moderats/feedbackform/savepublic'
export const FEEDBACK_SAVE_USER = 'moderats/feedbackform/saveusers'
export const FEEDBACK_VIEW = 'moderats/feedbackform/view'

export const CAPTCHA_SAVE = 'moderats/captcha/save'
export const CAPTCHA_VIEW = 'moderats/captcha/view'

export const EMAIL_TEMPLATE_LIST = 'moderats/mailtempl/list'
export const EMAIL_TEMPLATE_VIEW = 'moderats/mailtempl/view'
export const EMAIL_TEMPLATE_EDIT = 'moderats/mailtempl/edit'
export const EMAIL_TEMPLATE_RESTORE = 'moderats/mailtempl/restore'

export const M_ACCESS_GROUP_LIST = 'moderats/permission/list'
export const M_ACCESS_GROUP_VIEW = 'moderats/permission/view'
export const M_ACCESS_GROUP_DEFAULT = 'moderats/permission/default'
export const M_ACCESS_GROUP_ADD = 'moderats/permission/add'
export const M_ACCESS_GROUP_EDIT = 'moderats/permission/edit'
export const M_ACCESS_GROUP_DELETE = 'moderats/permission/delete'

export const MODERATOR_LIST = 'moderats/moderates/list'
export const MODERATOR_ADD = 'moderats/moderates/add'
export const MODERATOR_EDIT = 'moderats/moderates/edit'
export const MODERATOR_VIEW = 'moderats/moderates/view'
export const MODERATOR_CHANGE_PW = 'moderats/moderates/pass'
export const MODERATOR_DATA = 'moderats/moderates/selection'

export const REQUEST_CHAT_CONVERSATION = 'moderats/requestchat/chat/'
export const REQUEST_CHAT_COMMENT = 'moderats/requestchat/comment/'
export const REQUEST_CHAT_DIALOGS = 'moderats/requestchat/favorites/'
export const REQUEST_CHAT_MARK = 'moderats/requestchat/mark/'
export const REQUEST_CHAT_MESSAGES = 'moderats/requestchat/messages/'
export const REQUEST_CHAT_BLOCK_AVATAR = 'moderats/requestchat/blockavatars'
export const REQUEST_CHAT_BLOCK_REQUEST = 'moderats/requestchat/blockrequest'
export const REQUEST_CHAT_BLOCK_AVATAR_COMMENT = 'moderats/requestchat/bacomment'
export const REQUEST_CHAT_BLOCK_REQUEST_COMMENT = 'moderats/requestchat/brcomment'
export const REQUEST_CHAT_MESSAGES_UPDATE = 'moderats/requestchat/renewmess'
export const REQUEST_CHAT_CONVERSATION_UPDATE = 'moderats/requestchat/renewdialog'

export const LOGIN_FORBIDDEN_LIST = 'moderats/logins/list'
export const LOGIN_FORBIDDEN_ADD = 'moderats/logins/add'
export const LOGIN_FORBIDDEN_DELETE = 'moderats/logins/delete'
export const LOGIN_CHECK = 'moderats/logins/checklogin'
export const LOGIN_CHANGE = 'moderats/logins/newlogin'
export const LOGIN_REGISTERED = 'moderats/logins/users'

export const CATEGORY_LIST = 'moderats/categories/list'
export const CATEGORY_VIEW = 'moderats/categories/view'
export const CATEGORY_DELETE = 'moderats/categories/delete'
export const CATEGORY_ADD = 'moderats/categories/add'
export const CATEGORY_EDIT = 'moderats/categories/edit'

export const SMS_TEMPLATE_LIST = 'moderats/smstempl/list'
export const SMS_TEMPLATE_VIEW = 'moderats/smstempl/view'
export const SMS_TEMPLATE_EDIT = 'moderats/smstempl/edit'
export const SMS_TEMPLATE_RESTORE = 'moderats/smstempl/restore'

export const ORG_TRANSFER_LIST = 'moderats/orgusers/list'
export const ORG_TRANSFER_VIEW = 'moderats/orgusers/view'
export const ORG_TRANSFER_USERS = 'moderats/orgusers/users'
export const ORG_TRANSFER = 'moderats/orgusers/transition'

export const CURRENCY_LIST = 'moderats/currency/list'
export const CURRENCY_VIEW = 'moderats/currency/view'
export const CURRENCY_ADD = 'moderats/currency/add'
export const CURRENCY_EDIT = 'moderats/currency/edit'
export const CURRENCY_DELETE = 'moderats/currency/delete'
export const CURRENCY_COUNTRIES = 'moderats/currency/countries'

export const BACKUP_TABLES = 'moderats/backup/list'
export const BACKUP_GENERATE = 'moderats/backup/save'

export const MAILING_LIST = 'moderats/mailings/list'
export const MAILING_VIEW = 'moderats/mailings/view'
export const MAILING_CONTACTS = 'moderats/mailings/contactlist'
export const MAILING_CONTACT_ERROR = 'moderats/mailings/editerror'
export const MAILING_ACTION = 'moderats/mailings/action'

export const CRON_TASK_LIST = 'moderats/crontab/list'
export const CRON_TASK_VIEW = 'moderats/crontab/view'
export const CRON_TASK_ADD = 'moderats/crontab/add'
export const CRON_TASK_EDIT = 'moderats/crontab/edit'
export const CRON_TASK_DELETE = 'moderats/crontab/delete'
export const CRON_TASK_DATA = 'moderats/crontab/selection'

/**
 * Разбор ответа
 * @param response - ответ с сервера
 * @return обработанный ответ
 */
function parseResponse(response) {
    return response.then((res) => {
        return (res.result) ?
            { success: true, data: res.data } :
            (res.code === -1) ?
                { success: false, code: 0, data: res.error, errors: [], status: res.statusCode } :
                { success: false, code: res.code, data: res.error, errors: res.errors, status: res.statusCode }
    })
}

/**
 * Выполнение запроса
 * @param url - ссылка
 * @param body - данные запроса
 * @return обработанный ответ
 */
export function request(url, body, headers = false) { return parseResponse(post(url, body, headers)) }

/**
 * Выполнение запроса с Content-Type: application/json
 * @param url - ссылка
 * @param body - данные запроса
 * @return обработанный ответ
 */
export function jsonRequest(url, body, headers = false) { return parseResponse(json(url, body, headers)) }

/**
 * Выполнение запроса
 * @param url - ссылка
 * @param body - данные запроса
 * @param account - данные аккаунта
 * @param app - данные авторизации
 * @return обработанный ответ
 */
export function fetch(url, body, account, app) {
	let headers = {}
    if (account.logged) {
        headers = {
            ...headers,
            employeeId: account.employeeId,
            accountId: account.accountId
        }
    }
    if (app.authorization) {
        headers = {
            ...headers,
            auth: app.authorization
        }
    }
	return parseResponse(json(url, body, headers))
}

/**
 * Выполнение запроса выхода из системы
 */
export function logout() { return parseResponse(json(LOGOUT, {}, {})) }

export function dadataRequest(body, headers) { return parseResponse(dadata(body, headers)) }

export function dadataHidRequest(body, headers) { return parseResponse(findByDadataId(body, headers)) }

export function postRequest(url, body, headers = false) { return parseResponse(formData(url, body, headers)) }

