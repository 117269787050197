export const UPDATE_APP_STATE = 'UPDATE_APP_STATE'

/**
 * Обновление настроек приложения
 * @param settings - общие настройки
 */
export function updateAppState(settings) {
	return {
		payload: settings,
		type: UPDATE_APP_STATE
	}
}