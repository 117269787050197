export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT'

/**
 * Смена аккаунта
 * @param data - данные аккаунта
 */
export function changeAccount(settings) {
	return {
		payload: settings,
		type: CHANGE_ACCOUNT
	}
}