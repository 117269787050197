import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import * as R from '../requests/main'

import MailingList from './mailing/list'
import MailingView from './mailing/view'

class Test extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>

                    <Route path={ R.APP_ROUTE + '/mailings' } component={ MailingList } />
                    <Route path={ R.APP_ROUTE + '/mailing/view/:mailingId([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})' } component={ MailingView } />
                    
                </Switch>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app
})

export default connect(mapStateToProps)(Test)
