import React from 'react'
import { connect } from 'react-redux'
import Toast from './toast'
import * as Styled from './_styles'

/* Компонент списка уведомлений */
const Toasts = ({ actions, toasts }) => {
    return (
        <Styled.ToastList>
            { toasts.map(toast => 
            	<Styled.Toast key={ toast.id } bg={ toast.color }>
                	<Toast { ...toast } />
            	</Styled.Toast>
            )}
        </Styled.ToastList>
    )
}

const mapStateToProps = state => ({
    toasts: state.toasts
})

export default connect(mapStateToProps)(Toasts)
