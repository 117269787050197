import React, { Component, Fragment } from "react"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as R from '../../requests/main'
import { addToast } from '../../store/toast/actions'
import { Row, Col } from 'react-bootstrap'
import * as Styled from '../_styles'
import * as DateUtils from '../../utils/dateUtils'
import MailingListContacts from './contacts'
import Input from '../../components/input'

class MailingView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mailingId: '',
            comment: '',
            data: false
        }
    }

    componentDidMount() {
        const { mailingId } = this.props.match.params
        if (mailingId) {
            this.setState({
                mailingId: mailingId
            }, () => {
                this.getMailing()
            })
        }
    }

    getMailing() {
        let data = {
            mailingsId: this.state.mailingId
        }
        this.request(R.MAILING_VIEW, data, false, res => {
            this.setState({
                data: res.data
            })
        }, errors => {})
    }

    change(name, value) {
        this.setState({ [name]: value })
    }
    
    confirmComment() {
        this.setState({
            confirmWrap: true
        })
    }

    denyComment() {
        this.setState({
            denyWrap: true
        })
    }

    confirm() {
        let data = {
            mailingsId: this.state.mailingId,
            status: 1,
            comment: this.state.comment.trim()
        }
        this.request(R.MAILING_ACTION, data, false, res => {
            this.setState({
                confirmWrap: false,
                denyWrap: false,
                comment: ''
            }, () => {
                this.getMailing()
            })
        }, errors => {})
    }

    deny() {
        let data = {
            mailingsId: this.state.mailingId,
            status: 0,
            comment: this.state.comment.trim()
        }
        this.request(R.MAILING_ACTION, data, false, res => {
            this.setState({
                confirmWrap: false,
                denyWrap: false,
                comment: ''
            }, () => {
                this.getMailing()
            })
        }, errors => {})
    }

    request(url, data, headers, callback, showErrors) {
        const { app } = this.props
        if (app.authorization) {
            headers = {
                ...headers,
                auth: app.authorization
            }
        }
        R.jsonRequest(url, data, headers).then(res => {
            if (res.success) callback(res)
            else {
                if (res.errors.length > 0) showErrors(res.errors)
                const { addToast } = this.props.actions
                addToast({ text: res.data, error: res.code === 0 })
            }
        }, errors => {})
    }

    render() {
        const { data } = this.state
        return (
            <Styled.Card>
                <Styled.CardTitle back>
                    <Styled.CardBackBtn to={ R.APP_ROUTE + '/mailings' } />
                    Рассылка приглашений
                </Styled.CardTitle>
                <Styled.CardContent>
                    { data &&
                        <Fragment>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <div>Номер: { data.numeric }</div>
                                        <div>Организация: { data.organizations.abbreviation }</div>
                                        <div>Название: { data.name }</div>
                                        {/* <div>Описание: { data.description }</div> */}
                                        <div>Статус: { data.statusText }</div>
                                        <div>Контактов: { data.contact }</div>
                                        <div>Приглашений: { data.invitations }</div>
                                        <div>Рассылок: { data.mailings }</div>
                                        <div>Дата: { DateUtils.formatDateTime(data.dateAdd) }</div>
                                    </div>
                                </Col>
                                <Col>
                                    { data.cancelComment !== '' &&
                                        <div className="mb-3">
                                            <div>Комментарий модератора:</div>
                                            { data.cancelComment }
                                        </div>
                                    }
                                </Col>
                            </Row>

                            { data.status === 'moderat' &&
                                <Fragment>

                                    { !this.state.confirmWrap && !this.state.denyWrap &&
                                        <div className="mb-3">
                                            <Styled.Button small onClick={ () => this.confirmComment() }>
                                                Согласовать
                                            </Styled.Button>

                                            <Styled.Button small onClick={ () => this.denyComment() } className="ml-3">
                                                Отказать
                                            </Styled.Button>
                                        </div>
                                    }

                                    { this.state.confirmWrap &&
                                        <div className="mb-3">
                                            <Input onChange={ (name, value) => this.change(name, value) }
                                                    data={{ key: 'comment',
                                                            value: this.state.comment,
                                                            label: '', type: 'textarea', rows: '3' }} />
                                            <Styled.Button small onClick={ () => this.confirm() }>
                                                Согласовать
                                            </Styled.Button>
                                        </div>
                                    }

                                    { this.state.denyWrap &&
                                        <div className="mb-3">
                                            <Input onChange={ (name, value) => this.change(name, value) }
                                                    data={{ key: 'comment',
                                                            value: this.state.comment,
                                                            label: '', type: 'textarea', rows: '3' }} />
                                            <Styled.Button small onClick={ () => this.deny() }>
                                                Отказать
                                            </Styled.Button>
                                        </div>
                                    }

                                </Fragment>
                            }

                            <MailingListContacts mailingId={ this.state.mailingId } />

                        </Fragment>
                    }
                </Styled.CardContent>
            </Styled.Card>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        addToast
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MailingView)
