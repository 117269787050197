import { PRODUCT_OFFER, ADD_OFFER, DELETE_OFFER, CLEAR_OFFERS } from './actions'

const initialState = {
	showProductOffers: false,
	showContractorOffers: false,
	product: false,
	contractor: false,
	offers: [],
	groupedOffers: [],
	currency: ''
}

export default function productOffers(state = initialState, action) {
	const { payload, type } = action

	let contractors = []
	let groupOffers = []

	function productExists(offer) {
		let exists = false
		for (let k=0; k<groupOffers.length; k++) {
			if (groupOffers[k].productId === offer.productId) {
				exists = true
				break
			}
		}
		return exists
	}

	function addOfferToProduct(offer, productId) {
		let j, n
		let totalAmount, averagePrice, sum
		for (n=0; n<groupOffers.length; n++) {
			if (groupOffers[n].productId === productId) {
				groupOffers[n].contractors = [
					...groupOffers[n].contractors,
					{
						offerId: offer.offerId,
						contractorId: offer.contractorId,
						contractor: offer.contractor,
						amount: offer.amount,
						price: offer.price
					}
				]
				break
			}
		}

		for (n=0; n<groupOffers.length; n++) {
			totalAmount = 0
			averagePrice = 0
			sum = 0
			for (j=0; j<groupOffers[n].contractors.length; j++) {
				totalAmount += groupOffers[n].contractors[j].amount
				sum += groupOffers[n].contractors[j].amount * groupOffers[n].contractors[j].price
			}
			groupOffers[n].totalAmount = totalAmount
			groupOffers[n].averagePrice = (sum / totalAmount).toFixed(2)
		}
	}

	function group(offers) {
		groupOffers = []
		for (let i=0; i<offers.length; i++) {
			if (!productExists(offers[i])) {
				groupOffers = [
					...groupOffers,
					{
						productId: offers[i].productId,
						product: offers[i].product,
			            category: offers[i].category,
			            parentCategory: offers[i].parentCategory,
			            contractors: [
			            	{
								offerId: offers[i].offerId,
								contractorId: offers[i].contractorId,
								contractor: offers[i].contractor,
								amount: offers[i].amount,
								price: offers[i].price
							}
			            ],
			            totalAmount: offers[i].amount,
			            averagePrice: offers[i].price,
			            toPurchase: offers[i].toPurchase
					}
				]
			} else {
				addOfferToProduct(offers[i], offers[i].productId)
			}
		}
		return groupOffers
	}

	switch (type) {
		case PRODUCT_OFFER:
			return { ...state, ...payload }

		case ADD_OFFER:
			/*
				payload = {
					offerId
					productId
					product
		            category
		            parentCategory
					contractorId
					contractor
					amount
					price
				}
			*/
			if (state.offers.some(item => item.offerId === payload.offerId))
				return state
			else {
				let offers = [
					...state.offers,
					payload
				]
				console.log('offers', offers)
				let grouped = [ ...group(offers) ]
				return {
					...state,
					groupedOffers: [ ...grouped ],
					offers: [ ...offers ]
				}
			}

		case DELETE_OFFER:
			let offers = [ ...state.offers.filter(item => item.offerId !== payload) ]
			let grouped = [ ...group(offers) ]
			return {
				...state,
				groupedOffers: [ ...grouped ],
				offers: [ ...offers ]
			}

		case CLEAR_OFFERS:
			return initialState

		default:
			return state
	}
}