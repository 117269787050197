import { CHANGE_STATE, DELETE_CATEGORY } from './actions'

const initialState = {
	show: false,
	for: '',
	tree: [],
	selected: []
}

function iterate(array, openId) {
	let id = []
	array.map(item => {
		if (parseInt(item.id) === parseInt(openId)) id = [ openId ]
		let res = iterate(item.child, openId)
		item.child = res.child
		if (res.id.length > 0) id = [ ...res.id, item.id ]
		return item
	})
	return {
		child: array,
		id: id
	}
}

function expandTree(state) {
	let res = iterate(state.tree, state.openTree)
	console.log(res)
	return {
		...state,
		tree: res.child,
		openNode: res.id.reverse()
	}
}

export default function category(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case CHANGE_STATE:
			let newState = {
				...state,
				...payload
			}
			if (newState.openTree && newState.tree.length > 0) {
				newState = expandTree(newState)
			}
			return newState

		case DELETE_CATEGORY:
			let categories = [ ...state.selected.filter(item => item.id !== payload) ]
			return {
				...state,
				selected: [ ...categories ]
			}

		default:
			return state
	}
}