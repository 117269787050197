export const PRODUCT_OFFER = 'PRODUCT_OFFER'
export const ADD_OFFER = 'ADD_OFFER'
export const DELETE_OFFER = 'DELETE_OFFER'
export const CLEAR_OFFERS = 'CLEAR_OFFERS'

export function showOffers(settings) {
	return {
		payload: settings,
		type: PRODUCT_OFFER
	}
}

export function addOffer(settings) {
	return {
		payload: settings,
		type: ADD_OFFER
	}
}

export function deleteOffer(id) {
	return {
		payload: id,
		type: DELETE_OFFER
	}
}

export function clearOffers() {
	return {
		payload: false,
		type: CLEAR_OFFERS
	}
}