import React, { Component, Fragment } from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import * as Styled from '../ui/_styles'

/* Компонент для вывода input, select 
 * { key: '', value: '', label: '' },
 * { key: '', value: '', label: '', type: 'password' },
 * { key: '', value: '', label: '', inputType: 'phone' },
 * { key: '', value: '', label: '', type: 'email' },
 * { key: '', value: false, label: '', type: 'checkbox' },
 * { key: '', value: '', label: '', type: 'select', options: [ {key,value} ] }
 * { key: '', value: '', label: '', type: 'textarea', rows: '3' }
 */
class Input extends Component {

	constructor(props) {
		super(props)
		this.handleChange = this.handleChange.bind(this)
	}

	/**
	 * Событие на изменение данных
	 * @param e - событие onChange
	 */
	handleChange(e) {
        const { checked, name, value, type } = e.target
        const valueToUpdate = type === 'checkbox' ? checked : value
		this.props.onChange(name, valueToUpdate)
	}

	/**
	 * Событие на нажатие кнопки
	 * @param e - событие onKeyDown
	 */
	keyDown(e) {
    	if (['1','2','3','4','5','6','7','8','9','0'].includes(e.key) ||
    			[
    				8, // Backspace
    				9, // Tab
    				13, // Enter
    				35, // End
    				36, // Home
    				37, // ArrowLeft
    				38, // ArrowUp
    				39, // ArrowRight
    				40, // ArrowDown
    				46 // Delete
					// Control 17
					// v 86
    			].includes(e.keyCode)) {} else {
    		e.preventDefault()
    	}
	}

	render() {
		const { data, style, inline, noLabel, searchIcon, disabled } = this.props
		let stl = {
			...style
		}
		if (inline) {
			stl = {
				...stl,
				marginBottom: 0,
				display: 'inline-block',
				verticalAlign: 'bottom'
			}
		}
		return (
			<Form.Group controlId={ 'inp-' + data.key } style={ stl }>

				{ data.type !== 'checkbox' && data.type !== 'select' && data.type !== 'textarea' &&
					<Fragment>
						{ !noLabel && <Styled.Label>{ data.label }</Styled.Label> }
						<Styled.TextInput 
							searchIcon={ searchIcon }
							type={ data.type ? data.type : 'text' }
							name={ data.key }
							value={ data.value }
							placeholder={ data.placeholder }
							onKeyDown={ e => { 
								if (data.inputType === 'phone') this.keyDown(e)
							}}
							onChange={ this.handleChange } />
					</Fragment>
				}

				{ data.type === 'textarea' &&
					<Fragment>
						<Styled.Label>{ data.label }</Styled.Label>
						<Styled.Textarea 
							name={ data.key }
							value={ data.value }
							rows={ data.rows ? data.rows : '3' }
							onChange={ this.handleChange } />
					</Fragment>
				}

				{ data.type === 'checkbox' &&
					<Styled.CustomCheckbox>
						<input type="checkbox" 
								name={ data.key } 
								id={ 'inp-' + data.key } 
								onChange={ this.handleChange } 
								checked={ data.value } />
						<label for={ 'inp-' + data.key }>{ data.label }</label>
					</Styled.CustomCheckbox>
				}

				{ data.type === 'select' &&
					<Fragment>
						{ !noLabel && <Styled.Label>{ data.label }</Styled.Label> }
                        <Styled.Select 
                        		disabled={ disabled }
                            	name={ data.key }
                                onChange={ this.handleChange }>
                            { data.options.map(item =>
                            	<option key={ item.key } 
                                		value={ item.key } 
                                		selected={ data.value === item.key }>
                            		{ item.value }
                        		</option>
                        	)}
                        </Styled.Select>
					</Fragment>
				}

				{ data.error &&
					<Form.Text className="text-danger">{ data.error }</Form.Text>
				}

			</Form.Group>
		)
	}

}

Input.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    noLabel: PropTypes.bool,
    searchIcon: PropTypes.bool
}

export default Input
