import { v1 as uuidv1 } from 'uuid'

const defaultOptions = {
	color: '#474747'
}

/**
 * Создание уведомления
 * @param options - настройки уведомления
 * @return объект уведомления
 */
export default function create(options) {
	return {
		...defaultOptions,
		...options,
		id: uuidv1()
	}
}
