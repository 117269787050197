import { CHANGE_UNIT_STATE } from './actions'

const initialState = {
	show: false,
	selected: false
}

export default function unit(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case CHANGE_UNIT_STATE:
			return { ...state, ...payload }

		default:
			return state
	}
}