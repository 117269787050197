export const ADD_TAB = 'ADD_TAB'
export const CHANGE_TAB = 'CHANGE_TAB'
export const CLOSE_TAB = 'CLOSE_TAB'
export const TABS_STATE = 'TABS_STATE'

export function addTab(settings) {
	return {
		payload: settings,
		type: ADD_TAB
	}
}

export function changeTab(settings) {
	return {
		payload: settings,
		type: CHANGE_TAB
	}
}

export function closeTab(settings) {
	return {
		payload: settings,
		type: CLOSE_TAB
	}
}

export function tabsState(settings) {
	return {
		payload: settings,
		type: TABS_STATE
	}
}
