import { ADD_TAB, CHANGE_TAB, CLOSE_TAB, TABS_STATE } from './actions'

const initialState = {
	chatTabs: [
		{
			documentId: 'msg-list',
			documentTitle: 'Сообщения',
			date: '',
			active: true,
			list: true,
			link: '/requestschat/msglist'
		},
		{
			documentId: 'dialog-list',
			documentTitle: 'Диалоги',
			date: '',
			active: false,
			list: true,
			link: '/requestschat/dialoglist'
		}
	]
}

export default function tab(state = initialState, action) {
	const { payload, type } = action

	switch (type) {

		case TABS_STATE:
			return {
				...state,
				...payload
			}

		case CHANGE_TAB:
			return {
				...state,
				chatTabs: state.chatTabs.map(item => {
					item.active = item.documentId === payload.documentId
					return item
				})
			}

		case CLOSE_TAB:
			let _chatTabs = [ ...state.chatTabs.map(item => {
				item.active = item.documentId === 'dialog-list'
				return item
			}) ]
			return {
				...state,
				chatTabs: [ ..._chatTabs.filter(item => item.documentId !== payload) ]
			}

		case ADD_TAB:
			let opened = false
			let tabs = state.chatTabs.map(item => {
				item.active = item.documentId === payload.documentId
				if (item.documentId === payload.documentId) opened = true
				return item
			})
			if (!opened) {
				tabs = [
					...tabs,
					{
						...payload,
						active: true,
						list: false
					}
				]
			}
			return {
				...state,
				chatTabs: tabs
			}

		default:
			return state
	}
}