import React, { Component, Fragment } from "react"
import PropTypes from 'prop-types'
import LocalizedStrings from 'react-localization'
import styled from 'styled-components'
import * as Styled from '../ui/_styles'

const DTSortIcon = styled.span`
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    background: url(/images/dt_sort_arrow.png) 0 0 no-repeat;
    ${ props => props.asc && 'background-position: 0 -18px;' }
    ${ props => props.desc && 'background-position: 0 -36px;' }
`
const DTHeadCell = styled.div`
    cursor: pointer;
`

class DataTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sortList: []
        }
        this.strings = new LocalizedStrings({
            en: { loadMore: 'Загрузить еще' },
            ru: { loadMore: 'Загрузить еще' }
        })
        this.loadMore = this.loadMore.bind(this)
    }

    sortColumn(event, columnSelector) {
        if (event.ctrlKey) {
            let sortList = [ ...this.state.sortList ]
            if (sortList.filter(item => item.sort === columnSelector).length > 0) {
                sortList.map(item => {
                    if (item.sort === columnSelector) {
                        if (item.order === 'asc') item.order = 'desc'
                        else item.order = 'asc'
                    }
                    return item
                })
            } else {
                sortList = [ ...sortList, { sort: columnSelector, order: 'asc' } ]
            }

            this.setState(prevState => ({
                page: 1,
                sortList: [ ...sortList ]
            }), this.onChange)
        } else {
            let sortList = [ ...this.state.sortList ]
            let newSortList = [{ sort: columnSelector, order: 'asc' }]
            if (sortList.length === 1 && 
                    sortList[0].sort === columnSelector &&
                    sortList[0].order === 'asc')
                newSortList = [{ sort: columnSelector, order: 'desc' }]

            this.setState(prevState => ({
                page: 1,
                sortList: [ ...newSortList ]
            }), this.onChange)
        }
    }

    loadMore() {
        this.setState(prevState => ({
            page: prevState.page + 1
        }), this.onChange)
    }

    onChange() {
        this.props.onChange({
            sortList: this.state.sortList,
            page: this.state.page
        })
    }

    render() {
        const { columns, data, noDataText, loadMore } = this.props
        return (
            <Fragment>
                { data.length > 0 &&
                    <Fragment>
                        <Styled.Table>
                            <thead>
                                <tr>
                                    { columns.map((column, index) =>
                                        <th key={ index } style={ column.headerRight ? { textAlign: 'right' } : {} }>
                                            { column.sort &&
                                                <DTHeadCell onClick={ e => this.sortColumn(e, column.sort) }>
                                                    { column.name }
                                                    <DTSortIcon 
                                                            asc={ this.state.sortList
                                                                    .filter(item => item.sort === column.sort && item.order === 'asc')
                                                                    .length > 0 } 
                                                            desc={ this.state.sortList
                                                                    .filter(item => item.sort === column.sort && item.order === 'desc')
                                                                    .length > 0 } />
                                                </DTHeadCell>
                                            }
                                            { !column.sort &&
                                                <Fragment>{ column.name }</Fragment>
                                            }
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                { data.map((item, index) =>
                                    <tr key={ index } className={ item.test && 'test-data' }>
                                        { columns.map((column, index2) =>
                                            <td key={ index2 } valign={ column.valign ? column.valign : 'top' } 
                                                    style={ column.contentWidth ? { width: '1%' } : {} }>
                                                { column.cell &&
                                                    <Fragment>{ column.cell(item) }</Fragment>
                                                }
                                                { !column.cell &&
                                                    <Fragment>{ item[column.selector] }</Fragment>
                                                }
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </Styled.Table>

                        { loadMore &&
                            <div style={{ textAlign: 'center', paddingTop: '16px' }}>
                                <Styled.Button variant="link" onClick={ this.loadMore }>{ this.strings.loadMore }</Styled.Button>
                            </div>
                        }
                    </Fragment>
                }

                { data.length === 0 &&
                    <div style={{ textAlign: 'center' }}>{ noDataText }</div>
                }

            </Fragment>
        )
    }
}

DataTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    noDataText: PropTypes.string,
    loadMore: PropTypes.bool
}

export default DataTable
