import { CHAT_ACTION, ADD_ATTACHMENTS, DELETE_ATTACHMENT, CLEAR_ATTACHMENTS } from './actions'
import { v1 as uuidv1 } from 'uuid'

const initialState = {
	show: false,
	dialogId: '',
	attachments: [],
	mimeTypes: []
}

export default function chat(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case CHAT_ACTION:
			return { ...state, ...payload }

		case ADD_ATTACHMENTS:
			let files = []
			for (let i=0; i<payload.length; i++) {
				files = [
					...files,
					{ id: uuidv1(), file: payload[i] }
				]
			}
			return {
				...state,
				attachments: [
					...state.attachments,
					...files
				]
			}

		case DELETE_ATTACHMENT:
			return {
				...state,
				attachments: [
					...state.attachments.filter(item => item.id !== payload)
				]
			}

		case CLEAR_ATTACHMENTS:
			return { ...state, attachments: [] }

		default:
			return state
	}
}