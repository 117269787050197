import styled from 'styled-components'

export * from '../_styles'

export const ToastList = styled.div`
    bottom: 15px;
    position: fixed;
    left: 15px;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    z-index: 100;
`
export const Toast = styled.div`
	background: gray;
	background: ${ props => props.bg };
    min-height: 40px;
    border-radius: 10px;
    color: #fff;
    padding: 10px 40px 10px 15px;
    position: relative;
    align-self: flex-start;
    overflow: hidden;
    & + & {
    	margin-top: 12px;
    }
`
export const ToastContent = styled.div`
    max-height: 100px;
    overflow: auto;
`
export const ToastDismiss = styled.button`
    background: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    display: block;
    font: inherit;
    padding: 10px 16px;
    position: absolute;
    right: 0;
    top: 0;
`
export const ToastProgress = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 3px;
    background: #609aff;
`