export function formatDate(date) {
    let newDate = ''
    if (date && date !== '') {
        date = date.split(' ')
        date = date[0]
        const months = [
            'января', 
            'февраля', 
            'марта', 
            'апреля', 
            'мая', 
            'июня', 
            'июля', 
            'августа', 
            'сентября', 
            'октября', 
            'ноября', 
            'декабря'
        ]

        let d = new Date(date)
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(0)
        d.setMilliseconds(0)

        let today = new Date()
        today.setHours(0)
        today.setMinutes(0)
        today.setSeconds(0)
        today.setMilliseconds(0)

        let year = ' ' + d.getFullYear()
        if (d.getFullYear() === today.getFullYear()) year = ''

        if (today.getTime() === d.getTime()) newDate = 'сегодня'
        else newDate = d.getDate() + ' ' + months[d.getMonth()] + year
    }
    return newDate
}

export function convertDate(date) {
    const months = [
        'января', 
        'февраля', 
        'марта', 
        'апреля', 
        'мая', 
        'июня', 
        'июля', 
        'августа', 
        'сентября', 
        'октября', 
        'ноября', 
        'декабря'
    ]

    let d = new Date(date)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)

    return d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear()
}

export function formatTime(time) {
    let newTime = ''
    if (time && time !== '')
        newTime = time.split(':')[0] + ':' + time.split(':')[1]

    return newTime
}

export function formatDateTime(dateTime) {
    let newDate = ''
    if (dateTime && dateTime !== '') {
    	let date = dateTime.split(' ')
    	newDate = formatDate(date[0]) + ', ' + formatTime(date[1])
    }
    return newDate
}

export function secondsToTime(seconds) {
    let min = 0
    let s = '', m = ''
    if (seconds) {
        while (seconds >= 60) {
            seconds -= 60
            min++
        }
        if (seconds < 10) s = '0' + seconds
        else s = seconds
    } else s = '00'
    if (min < 10) m = '0' + min
    else m = min
    return m + ':' + s
}

export function dateToString(date) {
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let mm = m > 9 ? m : '0'+m
    let dd = d > 9 ? d : '0'+d
    return [ y, mm, dd ].join('-')
}

export function datetimeToString(date) {
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let mm = m > 9 ? m : '0'+m
    let dd = d > 9 ? d : '0'+d
    let hh = hours > 9 ? hours : '0'+hours
    let min = minutes > 9 ? minutes : '0'+minutes
    return [ y, mm, dd ].join('-') + ' ' + [ hh, min ].join(':')
}

export function _datetimeToString(date) {
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    let mm = m > 9 ? m : '0'+m
    let dd = d > 9 ? d : '0'+d
    let hh = hours > 9 ? hours : '0'+hours
    let min = minutes > 9 ? minutes : '0'+minutes
    let sec = seconds > 9 ? seconds : '0'+seconds
    return [ y, mm, dd ].join('_') + '_' + [ hh, min, sec ].join('_')
}

export function dateToDMHFormat(dateMs) {
    let date = new Date(dateMs)
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let mm = m > 9 ? m : '0'+m
    let dd = d > 9 ? d : '0'+d
    return [ dd, mm, y ].join('.')
}

export function secondsToDays(seconds) {
    let days = parseInt(seconds / (60*60*24));
    let hours = parseInt((seconds % (60*60*24)) / 3600);
    let result = ''

    let string1 = 'день'
    let string2 = 'дня'
    let string3 = 'дней'
    let hString1 = 'час'
    let hString2 = 'часа'
    let hString3 = 'часов'

    let ds = ''
    let hs = ''

    if (hours > 0) {
        let secondH = parseInt(hours/10)
        if (secondH > 9) {
            secondH = secondH % 10
        }
        if (secondH !== 1) {
            if (hours % 10 === 1) hs = hString1
            else if (hours % 10 === 2 || 
                hours % 10 === 3 || 
                hours % 10 === 4) hs = hString2
            else hs = hString3
        } else hs = hString3
    }

    if (days > 0) {
        let second = parseInt(days/10)
        if (second > 9) {
            second = second % 10
        }
        if (second !== 1) {
            if (days % 10 === 1) ds = string1
            else if (days % 10 === 2 || 
                days % 10 === 3 || 
                days % 10 === 4) ds = string2
            else ds = string3
        } else ds = string3

        result = days + ' ' + ds + ' ' + hours + ' ' + hs
    } else result = hours + ' ' + hs
    return result
}
