import create from '../../ui/toast/create'
export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'

/**
 * Добавление уведомления
 * @param options - настройки уведомления
 */
export function addToast(options = {}) {
	return {
		payload: create(options),
		type: ADD_TOAST
	}
}

/**
 * Удаление уведомления
 * @param id - id уведомления
 */
export function removeToast(id) {
	return {
		payload: id,
		type: REMOVE_TOAST
	}
}