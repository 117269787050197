import React, { Component } from "react"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as R from '../../requests/main'
import { addToast } from '../../store/toast/actions'
import DataTable from '../../components/dataTable'
import { Form } from 'react-bootstrap'
import * as DateUtils from '../../utils/dateUtils'
import * as Styled from '../_styles'

class MailingList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            list: [],
            page: 1,
            search: '',
            status: 'moderat'
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.getList(false)
    }

    getList(more) {
        let data = {
            start: this.state.page,
            status: this.state.status
        }
        if (this.state.search.trim() !== '')
            data = { ...data, query: this.state.search.trim() }
        this.request(R.MAILING_LIST, data, false, res => {
            let newList = more ? [ ...this.state.list, ...res.data.list ] : res.data.list
            this.setState({ 
                loading: false,
                list: newList,
                loadMore: res.data.count > newList.length
            })
        }, errors => {})
    }

    onTableChange(filters) {
        this.setState({
            page: filters.page
        }, () => {
            this.getList(filters.page > 1)
        })
    }

    change(name, value) {
        this.setState({
            [name]: value,
            page: 1
        }, () => this.getList(false))
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ 
            [name]: value,
            page: 1
        }, () => {
            this.getList(false)
        })
    }

    request(url, data, headers, callback, showErrors) {
        const { app } = this.props
        if (app.authorization) {
            headers = {
                ...headers,
                auth: app.authorization
            }
        }
        R.jsonRequest(url, data, headers).then(res => {
            if (res.success) callback(res)
            else {
                if (res.errors.length > 0) showErrors(res.errors)
                const { addToast } = this.props.actions
                addToast({ text: res.data, error: res.code === 0 })
            }
        }, errors => {})
    }

    render() {
        const columns = [
            {
                name: 'Номер',
                cell: row => row.numeric
            },
            {
                name: 'Логин',
                cell: row =>
                    <Styled.CustomLink to={ R.APP_ROUTE + '/mailing/view/' + row.mailingsId } primary>{ row.login }</Styled.CustomLink>
            },
            {
                name: 'Организация',
                cell: row => row.organizations.abbreviation
            },
            {
                name: 'Статус',
                cell: row => row.status
            },
            {
                name: 'Контактов',
                cell: row => row.contact
            },
            {
                name: 'Приглашений',
                cell: row => row.invitations
            },
            {
                name: 'Рассылок',
                cell: row => row.mailings
            },
            {
                name: 'Дата',
                cell: row => DateUtils.formatDateTime(row.dateAdd)
            }
        ]
        return (
            <Styled.Card>
                <Styled.CardTitle>Рассылки приглашений</Styled.CardTitle>
                <Styled.CardContent>
                    <Form.Group>
                        <Form.Control 
                            type="text" 
                            size="sm"
                            name="search"
                            value={ this.state.search }
                            onChange={ e => this.change('search', e.target.value) }
                            placeholder="Поиск" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Check inline type="radio" name="status" 
                                id="radio-moderat" 
                                value="moderat"
                                label="Модерация"
                                onChange={ this.handleChange }
                                checked={ this.state.status === 'moderat' } />
                        <Form.Check inline type="radio" name="status" 
                                id="radio-reject" 
                                value="reject"
                                label="Отказано"
                                onChange={ this.handleChange }
                                checked={ this.state.status === 'reject' } />
                        <Form.Check inline type="radio" name="status" 
                                id="radio-success" 
                                value="success"
                                label="Согласовано"
                                onChange={ this.handleChange }
                                checked={ this.state.status === 'success' } />
                    </Form.Group>

                    { this.state.loading ?
                        <Styled.Loader />
                        :
                        <DataTable 
                            columns={ columns } 
                            data={ this.state.list }
                            noDataText="Список пуст"
                            loadMore={ this.state.loadMore }
                            onChange={ filters => this.onTableChange(filters) } />
                    }
                </Styled.CardContent>
            </Styled.Card>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        addToast
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MailingList)
