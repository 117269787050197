import { CHANGE_AVATAR } from './actions'

const initialState = {
	logged: false
}

export default function avatar(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case CHANGE_AVATAR:
			return { ...state, ...payload }

		default:
			return state
	}
}