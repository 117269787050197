import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'react-router-dom'

export const COLOR_PRIMARY = '#753BEB'
export const COLOR_PRIMARY_RGB = '117,59,235'
export const COLOR_DANGER = '#EF5350'
export const COLOR_DIVIDER = 'rgba(34, 17, 68, 0.15)'

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Black.ttf');
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Bold.ttf');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Medium.ttf');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Regular.ttf');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Light.ttf');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Thin.ttf');
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-BlackItalic.ttf');
        font-weight: 900;
        font-style: italic;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-BoldItalic.ttf');
        font-weight: 700;
        font-style: italic;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-Italic.ttf');
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto-ThinItalic.ttf');
        font-weight: 100;
        font-style: italic;
    }

    *,
    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
    }
    body {
        font-family: 'Roboto', Arial, sans-serif;
        font-size: 15px;
        color: #000;
        margin: 0;
        background: #fff;
    }
    img {
        max-width: 100%;
    }
    a, a:hover {
        color: inherit;
        text-decoration: none;
    }
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        width: 100%;
    }
    .react-datepicker__input-container input {
        width: 100%;
        background: #F4F3F6;
        border: 1px solid rgba(34, 17, 68, 0.05);
        border-radius: 3px;
        padding: 8px 12px;
        line-height: 26px;
        height: 40px;
    }
    .test-data {
        background-image: url(/images/test_data.png);
        background-position: 0 0;
        background-repeat: repeat;
    }
    .mb-12 {
        margin-bottom: 12px;
    }
    .mb-24 {
        margin-bottom: 24px;
    }
    .chat-modal-backdrop { z-index: 3040; }
`
export const ButtonLink = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
`
export const Body = styled.div`
    padding: 15px;
`
    // position: relative;
    // overflow: hidden;
export const Card = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 15px;
    border: 1px solid #efefef;
    ${ props => props.warning && 'background: #ffdec6; border-color: #ecc7ab;' }
`
export const CardVFluid = styled(Card)`
    height: 100%;
`
export const CardTitle = styled.div`
    position: relative;
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: 700;
    color: #707070;
    transition: padding .1s ease-out;
    height: 40px;
    display: flex;
    flex-direction: row;
    padding-left: ${ props => props.back ? '50px' : '15px' };
    border-bottom: 1px solid #efefef;
`
export const CardBackBtn = styled(Link)`
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    display: block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAJFJREFUOI2lkzEOAiEQRd+IJ/IEdhDvYgOtyXoA6LwMVNY2nmTvsI3ZuLuIC/xuyLyXGZKBQkIId+/9rdQjJRgYPuXJOffO9R3/wSJytdZm4ewEGfjxC94IauGFoAWeBa0wgKx+uzqHVnCeAPpWUAAppafWWoAzcDHGjDHG125Bj0R9Fy0StX6olXQfUzF7znkCPl1XqiIwscUAAAAASUVORK5CYII=') center no-repeat;
`
export const CardBody = styled.div`
    flex: 1 1 auto;
`
export const CardContent = styled.div`
    padding: 15px;
`
export const CustomLink = styled(Link)`
    ${ props => props.underline && 'text-decoration: underline;' }
    ${ props => props.primary && 'color: ' + COLOR_PRIMARY + ';' }
`
export const CustomLinkText = styled.span`
    color: ${ COLOR_PRIMARY };
    cursor: pointer;
    ${ props => props.underline && 'text-decoration: underline;' }
`
export const Sup = styled.sup`
    margin-left: 5px;
    ${ props => props.danger && 'color: red;' }
`
export const DataTebleCell = styled.div`
    white-space: normal !important;
    padding-top: 8px;
    padding-bottom: 8px;
`
export const Loader = styled.div`
    width: 32px;
    height: 32px;
    margin: 15px auto;
    background: url(/images/loader.gif) center;
`
export const TextSmallMute = styled.div`
    font-size: 12px;
    color: rgba(0,0,0,0.5);
`
export const CardHeaderLink = styled.span`
    align-self: center;
    margin-left: 10px;
    font-size: 80%;
    cursor: pointer;
    &:first-child: {
        margin-left: 25px;
    }
    ${ props => props.active && 'color: #007bff;' }
`
export const StatusText = styled.span`
    ${ props => props.draft && 'color: #EA9E3C;' }
    ${ props => props.published && 'color: #9E41EA;' }
    ${ props => props.done && 'color: #317EEA;' }
    ${ props => props.completed && 'color: #4BBE49;' }
    ${ props => props.canceled && 'color: #7A7A7A;' }
    ${ props => props.deleted && 'color: #FF4D4D;' }
`
export const IconHelp = styled.span`
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: url(/images/help_outline-24px.svg) center no-repeat;
`
export const Panel = styled.div`
    ${ props => props.light && 'background: #f8f9fa;' }
    ${ props => props.mb && 'margin-bottom: 15px;' }
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding: 15px;
`
export const AdvancedSearchWrap = styled.div`
    position: relative;
    & .btn {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
`
export const Title = styled.div`
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 15px;
    ${ props => props.actions && `
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`
export const WeightBlock = styled.div`
    font-weight: ${ props => props.weight };
`
export const Tabs = styled.div`
    border-bottom: 1px solid rgba(34, 17, 68, 0.15);
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const TabItems = styled.div`
`
export const TabItem = styled(Link)`
    display: inline-block;
    vertical-align: top;
    padding: 14px 0;
    margin-right: 24px;
    color: rgba(0, 0, 0, ${ props => props.active ? '1' : '0.5'});
    position: relative;
    &::after {
        display: ${ props => props.active ? 'block' : 'none'};
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        height: 3px;
        background: ${ COLOR_PRIMARY };
        border-radius: 3px;
    }
`
export const TabActions = styled.div`
    align-self: center;
`



export const Button = styled.button`
    display: ${ props => props.block ? 'block' : 'inline-block' };
    ${ props => props.block && 'width: 100%;' }
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    &:focus {
        outline: none;
    }
    text-align: center;

    padding: 12px 16px;
    font-size: 15px;
    line-height: 24px;
    border-radius: 3px;

    background-color: ${ COLOR_PRIMARY };
    border-color: ${ COLOR_PRIMARY };
    color: #fff;
    &:hover {
        color: #fff;
    }

    font-weight: 500;
    ${ props => props.small && `
        font-weight: 400;
        padding: 6px 16px;
        line-height: 20px;
    `}

    ${ props => props.variant === 'outline' && `
        background-color: transparent;
        color: ${ COLOR_PRIMARY };
        &:hover {
            color: ${ COLOR_PRIMARY };
        }
    `}
    
    ${ props => props.variant === 'link' && `
        background-color: transparent;
        border-color: transparent;
        color: ${ COLOR_PRIMARY };
        &:hover {
            color: ${ COLOR_PRIMARY };
        }
    `}
    
    ${ props => props.variant === 'danger' && `
        background-color: ${ COLOR_DANGER };
        border-color: ${ COLOR_DANGER };
    `}
`
export const FileInput = styled.div`
    display: ${ props => props.block ? 'block' : 'inline-block' };
    vertical-align: middle;
    position relative;
    overflow: hidden;
    background-color: transparent;
    border: 1px solid ${ COLOR_PRIMARY };
    color: ${ COLOR_PRIMARY };
    text-align: center;
    padding: 6px 10px;
    line-height: 24px;
    border-radius: 3px;
    & input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0
    }
`
export const Icon = styled.span`
    display: inline-block;
    vertical-align: top;
    width: ${ props => props.middle ? '32px' : '24px' };
    height: ${ props => props.middle ? '32px' : '24px' };
    cursor: pointer;
    background: url(/images/${ props => props.icon }) center no-repeat;
`
export const Label = styled.label`
    font-size: 13px;
    opacity: 0.6;
    margin-bottom: 4px;
    line-height: 18px;
`
export const Textarea = styled.textarea`
    display: block;
    width: 100%;
    background: #F4F3F6;
    border: 1px solid rgba(34, 17, 68, 0.05);
    border-radius: 3px;
    padding: 8px 12px;
    line-height: 26px;
`
export const TextInput = styled.input`
    display: block;
    width: 100%;
    background: #F4F3F6;
    border: 1px solid rgba(34, 17, 68, 0.05);
    border-radius: 3px;
    padding: 8px 12px;
    line-height: 26px;
    height: 40px;
    ${ props => props.searchIcon && `
        background-image: url(/images/search-24px.svg);
        background-position: 12px center;
        background-repeat: no-repeat;
        padding-left: 44px;
    `}
`
export const Select = styled.select`
    display: block;
    width: 100%;
    background: #F4F3F6;
    border: 1px solid rgba(34, 17, 68, 0.05);
    border-radius: 3px;
    padding: 8px 12px;
    line-height: 26px;
    height: 40px;
    &:disabled {
        opacity: 1;
        color: #000;
    }
`
export const InlineBlockBottom = styled.div`
    display: inline-block;
    vertical-align: bottom;
`
export const IconRightBlock = styled.div`
    text-align: right;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
`
export const StatusBadge = styled.span`
    display: inline-block;
    vertical-align: top;
    padding: 4px 8px;
    line-height: 24px;
    border-radius: 3px;

    background: rgba(34, 17, 68, 0.5);
    color: #fff;

    ${ props => props.small && `
        padding: 2px 6px;
        line-height: 20px;
        font-size: 13px;
    `}
    ${ props => props.yellow && `
        background: rgba(242,180,28,0.3);
        color: #252831;
    `}
    ${ props => props.waiting && `
        background: rgba(117,59,235,0.15);
        color: #221144;
    `}
    ${ props => props.approved && `
        background: rgba(67,160,71,0.15);
        color: #43A047;
    `}
    ${ props => props.reject && `
        background: rgba(239,83,80,0.15);
        color: #EF5350;
    `}
`
export const Table = styled.table`
    width: 100%;
    & thead tr th {
        padding-bottom: 4px;
        padding-right: 10px;
        font-weight: 500;
        color: rgba(0,0,0,0.5);
    }
    & thead tr th:last-child {
        padding-right: 0;
    }
    & tbody tr td {
        padding: 16px 10px 16px 0;
    }
    & tbody tr:not(.borderless-table-row) td {
        border-bottom: 1px solid rgba(34, 17, 68, 0.15);
    }
    & tbody tr td:last-child {
        padding-right: 0;
    }
`
export const IconButton = styled.div`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: ${ COLOR_PRIMARY };
    & ${ Icon } {
        margin-right: 12px;
    }
`
export const TableInput = styled.input`
    display: block;
    width: 100%;
    border: none;
    padding: 0;
`
export const TableTextButton = styled.span`
    cursor: pointer;
    color: rgba(0,0,0,${ props => props.mute ? '0.6' : '1' });
`
export const ModalHeader = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid ${ COLOR_DIVIDER };
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
`
export const ModalBody = styled.div`
    padding: 24px 24px 20px 24px;
`
export const ModalContent = styled.div`
    padding: 20px 0;
`
export const ModalFooter = styled.div`
    padding-top: 20px;
    border-top: 1px solid ${ COLOR_DIVIDER };
    display: flex;
    justify-content: flex-end;
    & ${ Button } {
        margin-left: 12px;
    }
`
export const SideButtonBlock = styled.div`
    display: flex;
    justify-content: space-between;
`
export const SideModalFixedButtons = styled(SideButtonBlock)`
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 332px;
    & ${ Button } {
        width: 160px;
    }
`
export const SideModalDialog = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    width: 380px;
`
export const FullModalDialog = styled.div`
    position: fixed;
    top: 0;
    left: 240px;
    right: 0;
    bottom: 0;
    background: #fff;
`
export const SideModalBody = styled.div`
    padding: 32px 24px;
    ${ props => props.nospace && 'padding: 0;' }
    ${ props => props.fixedButtons && 'padding-bottom: 100px;' }
`
export const SideModalTitle = styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    ${ props => props.nospace && 'padding: 24px 24px 0;' }
    ${ props => props.actions && `
        display: flex;
        justify-content: space-between;
    `}
`
export const ReadOnlyInput = styled.div`
    display: block;
    width: 100%;
    background: #F4F3F6;
    border: 1px solid rgba(34, 17, 68, 0.05);
    border-radius: 3px;
    padding: 8px 12px;
    line-height: 26px;
    height: 40px;
    margin-bottom: 16px;
`
export const SideModalBlock = styled.div`
    padding: 16px 24px;
    ${ props => props.side && `
        display: flex;
        justify-content: space-between;
    `}
    & + & {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
    }
`
export const SideModalBlockTitle = styled.div`
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.5);
`
export const SideModalBlockData = styled.div`
    text-align: right;
`
export const CommentCloud = styled.div`
    padding: 12px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    & + & {
        margin-top: 12px;
    }
`
export const CommentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
`
export const CommentName = styled.div`
    display: flex;
    align-items: center;
`
export const CommentSender = styled.div`
    font-weight: 700;
`
export const CommentDate = styled.div`
    font-size: 13px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.5);
`
export const CommentDay = styled.div`
    margin-top: 12px;
`
export const NewCommentWrapper = styled.div`
    padding: 15px 16px;
    background: #F4F3F6;
    display: flex;
    align-items: center;
`
export const NewCommentInput = styled.input`
    border: none;
    background: transparent;
    flex: 1;
    padding: 0 16px 0 0;
`








export const RequestHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`
export const RequestTitle = styled.div`
    display: flex;
    align-items: center;
`
export const RequestNumber = styled.div`
    margin-top: 4px;
    font-size: 15px;
    color: rgba(0,0,0,.5);
`
export const RequestDate = styled.div`
    font-weight: 700;
    font-size: 17px;
    margin-right: 8px;
`
export const InlineData = styled.div`
    display: flex;
    margin-bottom: 32px;
`
export const InlineDataItem = styled.div`
    margin-right: 36px;
`
export const InlineDataTitle = styled.div`
    color: rgba(0,0,0,0.5);
`
export const SubtitleWrapper = styled.div`
    border-bottom: 1px solid ${ COLOR_DIVIDER };
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`
export const Subtitle = styled.div`
    font-weight: 500;
    font-size: 17px;
`
export const ProductCategories = styled.span`
    display: inline-block;
    vertical-align: top;
    padding: 4px 12px;
    border-radius: 3px;
    font-size: 13px;
    line-height: 24px;
    margin-left: 4px;
    cursor: pointer;
    background: ${ COLOR_DIVIDER };
    ${ props => props.active && `
        background: #252831;
        color: #fff;
    `}
`
export const CustomCheckbox = styled.div`
    & input[type="checkbox"] {
        display: none;
    }
    & input[type="checkbox"] + label {
        position: relative;
        padding-left: 30px;
        line-height: 20px;
        margin-bottom: 0px;
    }
    & input[type="checkbox"] + label::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 20px;
        height: 20px;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-image: url(/images/checkbox-off.svg);
    }
    & input[type="checkbox"]:checked + label::before {
        background-image: url(/images/checkbox-on.svg);
    }
`
export const CloseModalIcon = styled.span`
    z-index: 999;
    position: absolute;
    top: 12px;
    right: 12px;
`
export const ModalContainer = styled.div`
    padding: 0 24px 24px;
`
export const BothSideData = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    & > span:last-child,
    & > div:last-child {
        text-align: right;
        padding-left: 16px;
    }
    & + & {
        margin-top: 12px;
    }
`
export const MuteSpan = styled.span`
    color: rgba(0,0,0,.5);
`
export const MuteDiv = styled.div`
    color: rgba(0,0,0,.5);
`
export const SideBlock = styled.div`
    display: flex;
    justify-content: space-between;
    & > span:last-child,
    & > div:last-child {
        text-align: right;
        padding-left: 16px;
    }
    align-items: ${ props => props.bottom ? 'flex-end' : 'flex-start' };
`
export const TabLink = styled(Link)`
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;
    font-size: 19px;
    position: relative;
    padding-bottom: 12px;
    font-weight: ${ props => props.active ? '500' : '400' };
    color: rgba(0,0,0,${ props => props.active ? '1' : '0.5' });
    ${ props => props.active && `
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 4px;
            border-radius: 2px;
            background: ${ COLOR_PRIMARY };
        }
    `}
`
export const InlineInputs = styled.div`
    display: flex;
    align-items: flex-end;
`
export const ListItem = styled.div`
    padding: 5px 0;
    & + & {
        border-top: 1px solid ${ COLOR_DIVIDER };
    }
`

export const CodeWrapper = styled.code`
    padding: 10px;
    background: #f5f5f5;
    display: block;
    white-space: nowrap;
    overflow: auto;
`
export const CodeRow = styled.div`
    display: flex;
    white-space: pre;
`
export const CodeLine = styled.div`
    display: block;
    width: 40px;
    flex: 1 1 40px;
    max-width: 40px;
    min-width: 40px;
    color: #888;
`