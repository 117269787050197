import { UPDATE_APP_STATE } from './actions'

// authToken: base64 Authorization header
const initialState = {
	authorization: false,
	auth: false,
	lang: 1,
	accesses: false
}

export default function app(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case UPDATE_APP_STATE:
			return { ...state, ...payload }

		default:
			return state
	}
}