import { UPDATE_ACCESSES } from './actions'

const initialState = {
	ready: true,
	accesses: []/*,

	modulePosition: false,
	moduleEmployee: false,
	moduleAccessGroup: false,
	moduleInvitation: false,
	moduleCompany: false,

	positionList: false,
	positionView: false,
	positionAdd: false,
	positionEdit: false,
	positionDelete: false,

	employeeList: false,
	employeeView: false,
	employeeAdd: false,
	employeeEdit: false,
	employeeDelete: false,

	accessGroupList: false,
	accessGroupView: false,
	accessGroupAdd: false,
	accessGroupEdit: false,
	accessGroupDelete: false,

	invitationList: false,
	invitationView: false,
	invitationAdd: false,
	invitationEdit: false,
	invitationDelete: false,

	companyList: false,
	companyView: false,
	companyAdd: false,
	companyEdit: false,
	companyDelete: false,
	companyConfirm: false*/
}

export default function access(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case UPDATE_ACCESSES:
			let newState = { ...state }
			if (payload.accesses) {
	            payload.accesses.map(module => {
	                module.methods.map(method => {

	                    if (module.permissionId === 'officer') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, positionList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, positionView: method.status }
	                        if (method.methodsId === 'add')
	                            newState = { ...newState, positionAdd: method.status }
	                        if (method.methodsId === 'edit')
	                            newState = { ...newState, positionEdit: method.status }
	                        if (method.methodsId === 'delete')
	                            newState = { ...newState, positionDelete: method.status }
	                    }

	                    if (module.permissionId === 'employee') {
	                        if (method.methodsId === 'list') {
	                            newState = { ...newState, employeeList: method.status }
	                            newState = { ...newState, invitationList: true }
	                            newState = { ...newState, invitationView: true }
	                            newState = { ...newState, invitationAdd: true }
	                            newState = { ...newState, invitationEdit: true }
	                            newState = { ...newState, invitationDelete: true }
	                        }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, employeeView: method.status }
	                        if (method.methodsId === 'add')
	                            newState = { ...newState, employeeAdd: method.status }
	                        if (method.methodsId === 'edit')
	                            newState = { ...newState, employeeEdit: method.status }
	                        if (method.methodsId === 'delete')
	                            newState = { ...newState, employeeDelete: method.status }
	                    }

	                    if (module.permissionId === 'permission') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, accessGroupList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, accessGroupView: method.status }
	                        if (method.methodsId === 'add')
	                            newState = { ...newState, accessGroupAdd: method.status }
	                        if (method.methodsId === 'edit')
	                            newState = { ...newState, accessGroupEdit: method.status }
	                        if (method.methodsId === 'delete')
	                            newState = { ...newState, accessGroupDelete: method.status }
	                    }

	                    /*if (module.permissionId === 'staffinvite') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, invitationList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, invitationView: method.status }
	                        if (method.methodsId === 'add')
	                            newState = { ...newState, invitationAdd: method.status }
	                        if (method.methodsId === 'edit')
	                            newState = { ...newState, invitationEdit: method.status }
	                        if (method.methodsId === 'delete')
	                            newState = { ...newState, invitationDelete: method.status }
	                    }*/

	                    if (module.permissionId === 'organizations') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, companyList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, companyView: method.status }
	                        if (method.methodsId === 'add')
	                            newState = { ...newState, companyAdd: method.status }
	                        if (method.methodsId === 'edit')
	                            newState = { ...newState, companyEdit: method.status }
	                        if (method.methodsId === 'delete')
	                            newState = { ...newState, companyDelete: method.status }
	                        if (method.methodsId === 'confirm')
	                            newState = { ...newState, companyConfirm: method.status }
	                    }

	                    if (module.permissionId === 'worktables') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, avatarList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, avatarView: method.status }
	                        if (method.methodsId === 'add')
	                            newState = { ...newState, avatarAdd: method.status }
	                        if (method.methodsId === 'edit')
	                            newState = { ...newState, avatarEdit: method.status }
	                        if (method.methodsId === 'activity')
	                            newState = { ...newState, avatarActivity: method.status }
	                    }

	                    if (module.permissionId === 'contractors') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, contractorList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, contractorView: method.status }
	                        if (method.methodsId === 'reject')
	                            newState = { ...newState, contractorReject: method.status }
	                        if (method.methodsId === 'edit')
	                            newState = { ...newState, contractorEdit: method.status }
	                    }

	                    if (module.permissionId === 'mycontact') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, myContactList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, myContactView: method.status }
	                    }

	                    if (module.permissionId === 'qqcontact') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, contactRequestList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, contactRequestView: method.status }
	                        if (method.methodsId === 'add')
	                            newState = { ...newState, contactRequestAdd: method.status }
	                        if (method.methodsId === 'edit')
	                            newState = { ...newState, contactRequestEdit: method.status }
	                        if (method.methodsId === 'delete')
	                            newState = { ...newState, contactRequestDelete: method.status }
	                    }

	                    if (module.permissionId === 'order') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, orderList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, orderView: method.status }
	                    }

	                    if (module.permissionId === 'supply') {
	                        if (method.methodsId === 'list')
	                            newState = { ...newState, supplyList: method.status }
	                        if (method.methodsId === 'view')
	                            newState = { ...newState, supplyView: method.status }
	                    }

	                    return true
	                })
	                return true
	            })
	            newState = {
	            	...newState,
	            	accesses: [ ...payload.accesses.filter(item => item.status) ]
	            }
			}
            newState = {
            	...newState,
            	ready: payload.ready
            }
			return newState

		default:
			return state
	}
}