export const CHAT_ACTION = 'CHAT_ACTION'
export const ADD_ATTACHMENTS = 'ADD_ATTACHMENTS'
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT'
export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS'

export function chatAction(settings) {
	return {
		payload: settings,
		type: CHAT_ACTION
	}
}

export function addAttachments(files) {
	return {
		payload: files,
		type: ADD_ATTACHMENTS
	}
}

export function deleteAttachment(id) {
	return {
		payload: id,
		type: DELETE_ATTACHMENT
	}
}

export function clearAttachments() {
	return {
		payload: false,
		type: CLEAR_ATTACHMENTS
	}
}