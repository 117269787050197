import { NEW_OFFER } from './actions'

const initialState = {
	show: false,
	product: false,
	updateTable: false,
	currency: ''
}

export default function offer(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case NEW_OFFER:
			return { ...state, ...payload }

		default:
			return state
	}
}