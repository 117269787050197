import { CHANGE_ACCOUNT } from './actions'

const initialState = {
	logged: false
}

export default function account(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case CHANGE_ACCOUNT:
			return { ...state, ...payload }

		default:
			return state
	}
}