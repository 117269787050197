import { CHANGE_MODAL_STATE } from './actions'

const initialState = {
	show: false
}

export default function modal(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case CHANGE_MODAL_STATE:
			return { ...state, ...payload }

		default:
			return state
	}
}