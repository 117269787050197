export const AUTH_ERROR = 999
const BASE_URL = '/'

/**
 * Выполнение запроса
 * @param url - ссылка
 * @param body - данные запроса
 * @param additionalHeaders - дополнителные заголовки
 * @return ответ с сервера
 */
export function post(url, body, additionalHeaders = false) {
    let headers = {}
    if (additionalHeaders) {
        if (additionalHeaders.auth) {}
            /*headers = {
                ...headers,
                'Authorization': 'Basic ' + additionalHeaders.auth
            }*/
    }

    let err
    let options = {
            method: 'POST',
            headers: headers,
            body: body
        }
    return fetch(new Request(BASE_URL + url, options))
        .then(res => {
            err = res.clone()
            return res.json().then(data => {
                    return (data.result) ?
                        { result: true, data: data.data } :
                        { 
                            result: false, 
                            code: data.code, 
                            error: data.message,
                            errors: data.error
                        }
                })
        })
        .catch(error => {
            return err.text().then(e => {
                return {
                    result: false,
                    code: -1,
                    error: e.replace(/^(<br \/>)/, '')
                }
            }) 
        })
}

/**
 * Выполнение запроса с Content-Type: application/json
 * @param url - ссылка
 * @param body - данные запроса
 * @param additionalHeaders - дополнителные заголовки
 * @return ответ с сервера
 */
export function json(url, body, additionalHeaders = false) {
    let headers = {
        'Content-Type': 'application/json;charset=utf-8'
    }
    if (additionalHeaders) {
        if (additionalHeaders.contentType) {
            headers = { ...headers, 'Content-Type': additionalHeaders.contentType }
        }
        if (additionalHeaders.auth) {}
            /*headers = {
                ...headers,
                'Authorization': 'Basic ' + additionalHeaders.auth
            }*/

        if (additionalHeaders.avatarsId) {
            body = { ...body, avatarsId: additionalHeaders.avatarsId }
        }
        if (additionalHeaders.employeeId) {
            /*headers = {
                ...headers,
                'Employee-Id': additionalHeaders.employeeId
            }*/
            body = {
                ...body,
                employeeId: additionalHeaders.employeeId
            }
        }
        if (additionalHeaders.accountId) {
            /*headers = {
                ...headers,
                'Account-Id': additionalHeaders.accountId
            }*/
            body = {
                ...body,
                accountId: additionalHeaders.accountId
            }
        }
        if (additionalHeaders.eId) {
            /*headers = {
                ...headers,
                'Employee-Id': additionalHeaders.eId
            }*/
            body = {
                ...body,
                employeeId: additionalHeaders.eId
            }
        }
        if (additionalHeaders.aId) {
            /*headers = {
                ...headers,
                'Account-Id': additionalHeaders.aId
            }*/
            body = {
                ...body,
                accountId: additionalHeaders.aId
            }
        }
    }

    let err
    let options = { 
            method: 'POST',
            headers: headers
        }
    if (additionalHeaders && additionalHeaders.contentType === 'multipart/form-data') {
        options = { ...options, body: body }
    } else {
        options = { ...options, body: JSON.stringify(body) }
    }
    let statusCode = 0
    return fetch(new Request(BASE_URL + url, options))
        .then(res => {
            statusCode = res.status
            err = res.clone()
            return res.json().then(data => {
                    return (data.result) ?
                        { result: true, data: data.data } :
                        { 
                            result: false, 
                            code: data.code, 
                            error: data.message,
                            errors: data.error,
                            statusCode: statusCode
                        }
                })
        })
        .catch(error => {
            return err.text().then(e => {
                return {
                    result: false,
                    code: -1,
                    error: e.replace(/^(<br \/>)/, ''),
                    errors: [],
                    statusCode: statusCode
                }
            }) 
        })
}

export function dadata(body, data) {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token ' + data.token,
        'X-Secret': data.secret
    }
    let err
    let options = { 
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }
    let statusCode = 0
    return fetch(new Request('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party', options))
        .then(res => {
            statusCode = res.status
            err = res.clone()
            return res.json().then(data => {
                // console.log(data)
                return { result: true, data: data.suggestions }/*
                    return (data.result) ?
                        { result: true, data: data } :
                        { 
                            result: false, 
                            code: data.code, 
                            error: data.message,
                            errors: data.error,
                            statusCode: statusCode
                        }*/
                })
        })
        .catch(error => {
            return err.text().then(e => {
                return {
                    result: false,
                    code: -1,
                    error: e.replace(/^(<br \/>)/, ''),
                    errors: [],
                    statusCode: statusCode
                }
            }) 
        })
}

export function findByDadataId(body, data) {
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token ' + data.token,
        'X-Secret': data.secret
    }
    let err
    let options = { 
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }
    let statusCode = 0
    return fetch(new Request('https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party', options))
        .then(res => {
            statusCode = res.status
            err = res.clone()
            return res.json().then(data => {
                // console.log(data)
                return { result: true, data: data.suggestions }/*
                    return (data.result) ?
                        { result: true, data: data } :
                        { 
                            result: false, 
                            code: data.code, 
                            error: data.message,
                            errors: data.error,
                            statusCode: statusCode
                        }*/
                })
        })
        .catch(error => {
            return err.text().then(e => {
                return {
                    result: false,
                    code: -1,
                    error: e.replace(/^(<br \/>)/, ''),
                    errors: [],
                    statusCode: statusCode
                }
            }) 
        })
}




export function formData(url, body, additionalHeaders = false) {
    /*let headers = {
        'Content-Type': 'application/json;charset=utf-8'
    }
    if (additionalHeaders) {
        if (additionalHeaders.contentType) {
            headers = { ...headers, 'Content-Type': additionalHeaders.contentType }
        }
        if (additionalHeaders.auth)
            headers = {
                ...headers,
                'Authorization': 'Basic ' + additionalHeaders.auth
            }

        if (additionalHeaders.avatarsId) {
            body = { ...body, avatarsId: additionalHeaders.avatarsId }
        }
        if (additionalHeaders.employeeId) {
            // headers = {
                // ...headers,
                // 'Employee-Id': additionalHeaders.employeeId
            // }
            body = {
                ...body,
                employeeId: additionalHeaders.employeeId
            }
        }
        if (additionalHeaders.accountId) {
            // headers = {
                // ...headers,
                // 'Account-Id': additionalHeaders.accountId
            // }
            body = {
                ...body,
                accountId: additionalHeaders.accountId
            }
        }
        if (additionalHeaders.eId) {
            // headers = {
                // ...headers,
                // 'Employee-Id': additionalHeaders.eId
            // }
            body = {
                ...body,
                employeeId: additionalHeaders.eId
            }
        }
        if (additionalHeaders.aId) {
            // headers = {
                // ...headers,
                // 'Account-Id': additionalHeaders.aId
            // }
            body = {
                ...body,
                accountId: additionalHeaders.aId
            }
        }
    }*/

    let err
    let options = {
        method: 'POST',
        body: body
    }
    let statusCode = 0
    return fetch(new Request(BASE_URL + url, options))
        .then(res => {
            statusCode = res.status
            err = res.clone()
            return res.json().then(data => {
                    return (data.result) ?
                        { result: true, data: data.data } :
                        { 
                            result: false, 
                            code: data.code, 
                            error: data.message,
                            errors: data.error,
                            statusCode: statusCode
                        }
                })
        })
        .catch(error => {
            return err.text().then(e => {
                return {
                    result: false,
                    code: -1,
                    error: e.replace(/^(<br \/>)/, ''),
                    errors: [],
                    statusCode: statusCode
                }
            }) 
        })
}