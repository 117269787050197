import React, { Component } from "react"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as R from '../../requests/main'
import { addToast } from '../../store/toast/actions'

class MailingContactEdit extends Component {

	constructor(props) {
		super(props)
		this.state = {
            mailingId: '',
            contactId: '',
            text: ''
		}
        this.onBlur = this.onBlur.bind(this)
        this.handleChange = this.handleChange.bind(this)
	}
    
    componentDidMount() {
        const { mailingId, contactId, errorText } = this.props
        this.setState({
            contactId: contactId,
            mailingId: mailingId,
            text: errorText
        })
    }

    saveErrorText() {
        let data = {
            mailingsId: this.state.mailingId,
            contactId: this.state.contactId,
            info: this.state.text
        }
        this.request(R.MAILING_CONTACT_ERROR, data, res => {
        })
    }

    request(url, data, callback) {
        const { account, app } = this.props
        R.fetch(url, data, account, app).then(res => {
            if (res.success) callback(res)
            else {
                const { addToast } = this.props.actions
                if (res.errors.length > 0)
                    addToast({ text: res.errors[0].message, error: false })
            }
        }, errors => {})
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onBlur(e) {
        this.saveErrorText()
    }

	render() {
		return (
            <input type="text" name="text"
                value={ this.state.text }
                onBlur={ this.onBlur }
                onChange={ this.handleChange } />
		)
	}
}

const mapStateToProps = state => ({
    account: state.account,
    app: state.app
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
    	addToast
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MailingContactEdit)
