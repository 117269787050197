import { NEW_AMOUNT } from './actions'

const initialState = {
	show: false,
	product: false
}

export default function toPurchase(state = initialState, action) {
	const { payload, type } = action

	switch (type) {
		case NEW_AMOUNT:
			return { ...state, ...payload }

		default:
			return state
	}
}