import React, { Component, Suspense, lazy } from 'react'
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateAppState } from '../store/app/actions'
import { changeAccount } from '../store/account/actions'
import { addToast } from '../store/toast/actions'
import * as R from '../requests/main'
import Loading from './main/loading'
import Toasts from './toast/toasts'
import * as Styled from './_styles'
import cookie from 'react-cookies'

import 'bootstrap/dist/css/bootstrap.css'

const Content = lazy(() => import('./main/content'))

/* Стартовый компонент */
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            redirect: true
        }
    }
    
    componentDidMount() {
        this.setState({
            redirect: false
        }, () => {
            let token = cookie.load('token')
            if (token === 'authorized') {
                const { updateAppState } = this.props.actions
                updateAppState({ auth: true })
            }
            
            let employeeId = cookie.load('employeeId')
            let accountId = cookie.load('accountId')
            let accountCompany = cookie.load('accountCompany')
            let accountInn = cookie.load('accountInn')
            let accountOgrn = cookie.load('accountOgrn')

            if (employeeId && accountId) {
                this.logged = true
                this.accountId = accountId
                this.employeeId = employeeId
                // const { changeAccount } = this.props.actions
                /*changeAccount({
                    logged: true,
                    company: accountCompany,
                    inn: accountInn,
                    ogrn: accountOgrn,
                    position: '',
                    employeeId: employeeId,
                    accountId: accountId
                })*/
            }
        })
    }

    /**
     * Выполнение запроса
     * @param url - ссылка
     * @param data - данные запроса
     * @param headers - заголовки
     * @param callback - функция на успешное выполнение
     * @param showErrors - функция на отображение ошибок
     */
    request(url, data, headers, callback, showErrors) {
        if (this.logged) {
            headers = {
                ...headers,
                employeeId: this.employeeId,
                accountId: this.accountId
            }
        }
        R.jsonRequest(url, data, headers).then(res => {
            if (res.success) callback(res)
            else {
                if (res.errors && res.errors.length > 0) showErrors(res.errors)
                const { addToast } = this.props.actions
                if (res.status === 401) {
                    cookie.save('token', false, { path: '/' })
                    const { updateAppState } = this.props.actions
                    updateAppState({ auth: false })
                } else {
                    addToast({ text: res.data, error: res.code === 0 })
                }
            }
        }, errors => {})
    }
    
    render() {
        return (
            <BrowserRouter>
                <Styled.GlobalStyle />
                { this.state.redirect && <Redirect to={ window.location.pathname } /> }
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route component={ Content } />
                    </Switch>
                </Suspense>
                <Toasts />
            </BrowserRouter>
        )
    }
}

const mapStateToProps = state => ({
    account: state.account
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ 
        addToast,
        updateAppState,
        changeAccount
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
