export const UPDATE_ACCESSES = 'UPDATE_ACCESSES'

/**
 * Обновление доступов
 * @param accesses - доступы
 */
export function updateAccesses(accesses) {
	return {
		payload: accesses,
		type: UPDATE_ACCESSES
	}
}