import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { removeToast } from '../../store/toast/actions'
import * as Styled from './_styles'

/* Компонент уведомления */
class Toast extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	progress: 0
        }
        this.intervalCount = 0
        this.progressInterval = 10
        this.closeDelay = 10000
        this.removeToast = this.removeToast.bind(this)
    }

    componentDidMount() {
    	const { removeToast } = this.props.actions
    	let i = setInterval(() => this.changeProgress(), this.progressInterval)
        setTimeout(() => {
        	clearInterval(i)
        	removeToast(this.props.id)
        }, this.closeDelay)
    }

    /* Изменение шкалы времени уведомления */
    changeProgress() {
    	this.intervalCount++
    	this.setState({
    		progress: 100 * this.intervalCount * (this.progressInterval / this.closeDelay)
    	})
    }

    /* Закрытие уведомления */
	removeToast() {
    	const { removeToast } = this.props.actions
    	removeToast(this.props.id)
	}

	render() {
        const { text, error } = this.props
		return (
			<Fragment>
                { error && <Styled.ToastContent dangerouslySetInnerHTML={{ __html: text }} /> }
                { !error && <Styled.ToastContent>{ text }</Styled.ToastContent> }
                <Styled.ToastProgress style={{ width: this.state.progress + '%' }} />
				<Styled.ToastDismiss onClick={ this.removeToast }> x </Styled.ToastDismiss>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ removeToast }, dispatch)
})

export default connect(null, mapDispatchToProps)(Toast)